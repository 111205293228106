import * as Sentry from "@sentry/browser";
import {Integrations} from "@sentry/tracing";
import './sentry.scss';

const $ = require('jquery');
global.$ = global.jQuery = $;

const ENVIRONMENT = process.env.ENVIRONMENT;
const SENTRY_RELEASE = process.env.SENTRY_RELEASE;
const SENTRY_DSN = process.env.SENTRY_DSN;

if (document.querySelector('meta[data-user]') !== null) {
    let user = JSON.parse(document.querySelector('meta[data-user]').dataset.user);

    if (user) {
        let sentryUser = {
            id: user.id,
            username: user.username,
            name: user.username,
        };
        if (user.username.indexOf('@')) {
            sentryUser.email = user.username;
        }

        Sentry.setUser(sentryUser);
    }
}

function showReportDialog(eventId) {
    let el2 = $('.sentry-error-embed-wrapper');

    if(el2.length === 0) {
        Sentry.captureMessage('Missing sentry wrapper for user feeback.');
        return;
    }

    el2.css('display', 'block');

    el2.find('.close').on('click', function(e) {
        e.preventDefault();
        el2.css('display', 'none');

        return false;
    });

    el2.find('form').submit(function(e) {
        e.preventDefault();

        let data = new FormData(this);
        const userFeedback = {
            event_id: eventId,
            name: data.get('name'),
            email: data.get('email'),
            comments: data.get('comments')
        };

        Sentry.captureUserFeedback(userFeedback);

        el2.find('button.submit').remove();
        el2.find('.form-content').html('<p class="message-success">Zgłoszenie zostało wysłane. Dziękujemy!</p>');

        return false;
    });
}

Sentry.init({
    environment: ENVIRONMENT,
    tunnel: '/sentry/tunnel',
    release: SENTRY_RELEASE,
    dsn: SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    beforeSend: (event, hint) => {
        if (event.exception) {
            showReportDialog(event.event_id);
        }
        return event;
    },
    tracesSampleRate: 0.2
});

if (document.querySelector('body[data-sentry-last-event-id]') !== null) {
    let id = document.querySelector('body[data-sentry-last-event-id]').dataset.sentryLastEventId;

    if (id) {
        setTimeout(function () {
            showReportDialog(id);
        }, 2500);
    }
}

window.Sentry = Sentry;
